<template>
  <div class="all-bg">
    <div class="class-info">
      <div class="c h">
        <div class="img fl">
          <span
            v-if="classDetail1.type_name"
            :class="classDetail1.type == 1 ? 'class-tags' : 'class-tags major'"
            >#{{ classDetail1.type_name }}</span
          >
          <img :src="classDetail1.photo" alt />
        </div>
        <div class="txt fr">
          <div class="title">
            <span>{{ classDetail1.name }}</span>
          </div>
          <div class="sub-title" style="background: unset !important">
            {{ classDetail1.category_name }}
          </div>
          <div class="sub-info">{{ classDetail1.abstract }}</div>
          <div class="sub-lines">
            <div class="sub-time">
              <span class="iconfont iconshijian"></span>
              课程总时长：{{ classDetail1.total_time }}
            </div>
            <div class="sub-num">
              <span class="iconfont iconduoren"></span>
              {{ classDetail1.learn_num }}人已学
            </div>
          </div>
          <div v-if="classDetail1.origin_name" class="sub-source">
            <img src="../../assets/site/source-icon.png" />
            课程来源：{{ classDetail1.origin_name }}
          </div>

          <div class="btn-video-detail" @click="btnStudy()">
            <!-- course_id -->
            <div class="btn-xuexi">
              立即学习
              <!-- {{ classDetail1.type == 2 ? "￥199 购买学习" : "立即学习" }} -->
              <span class="iconfont iconright"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="class-mulu c h">
      <div class="fl mulu">
        <div class="title">
          <span
            :class="menuActive == 1 ? 'jieshao cur' : 'jieshao'"
            @click="muluClick(1)"
            >课程目录</span
          >
          <span
            :class="menuActive == 2 ? 'jieshao cur' : 'jieshao'"
            @click="muluClick(2)"
            >课程介绍</span
          >
          <span
            :class="menuActive == 3 ? 'jieshao cur' : 'jieshao'"
            @click="muluClick(3)"
            >课程评价</span
          >
        </div>
        <div class="menu-ul" v-if="menuActive == 1">
          <template v-if="this.classDetail2 && this.classDetail2.length > 1">
            <a-collapse
              default-active-key="0"
              v-model="downInx"
              @change="collapseChange"
              :bordered="false"
            >
              <template #expandIcon="props">
                <div class="">
                  <div
                    :class="
                      props.isActive
                        ? 'iconfont icontop'
                        : 'iconfont iconbottom'
                    "
                  ></div>
                </div>
              </template>
              <a-collapse-panel
                :header="item.name"
                v-for="(item, i) in this.classDetail2"
                :key="String(i)"
              >
                <div
                  class="menu-li"
                  v-for="(children, s) in item.children"
                  :key="String(s)"
                >
                  <template v-if="children.chapter_type == 1">
                    <div class="menu-title" v-if="children.name">
                      {{ children.name }}
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :class="
                        sz_cm_id == children.cm_id
                          ? 'menu-sub active'
                          : 'menu-sub'
                      "
                      v-if="children.name"
                      @click="materialPlay(children)"
                    >
                      {{ children.name }}
                    </div>
                  </template>

                  <!-- fen -->
                  <div
                    :class="children.name ? 'munu-list' : 'munu-list preMulu'"
                    v-for="(mate, m) in children.children"
                    :key="m"
                  >
                    <div
                      v-if="sz_cm_id == mate.cm_id"
                      class="menu-txt active"
                      @click="materialPlay(mate)"
                    >
                      {{ mate.name }}
                    </div>
                    <div v-else class="menu-txt" @click="materialPlay(mate)">
                      {{ mate.name }}
                    </div>
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </template>
          <!-- 仅有一个章节时 不显示一级章节名称 -->
          <template v-else>
            <template v-for="item in classDetail2">
              <div
                class="menu-li"
                v-for="(children, s) in item.children"
                :key="String(s)"
              >
                <template v-if="children.chapter_type == 1">
                  <div class="menu-title" v-if="children.name">
                    {{ children.name }}
                  </div>
                </template>
                <template v-else>
                  <div
                    :class="
                      sz_cm_id == children.cm_id
                        ? 'menu-sub active'
                        : 'menu-sub'
                    "
                    v-if="children.name"
                    @click="materialPlay(children)"
                  >
                    {{ children.name }}
                  </div>
                </template>
                <template v-if="children.children">
                  <!-- fen -->
                  <div
                    :class="children.name ? 'munu-list' : 'munu-list preMulu'"
                    v-for="(mate, m) in children.children"
                    :key="m"
                  >
                    <div
                      v-if="sz_cm_id == mate.cm_id"
                      class="menu-txt active"
                      @click="materialPlay(mate)"
                    >
                      {{ mate.name }}
                    </div>
                    <div v-else class="menu-txt" @click="materialPlay(mate)">
                      {{ mate.name }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
        <div class="menu-ul-2" v-if="menuActive == 2">
          <div class="intro-imgs" v-html="this.classDetail1.intro"></div>
        </div>
        <!-- 课程评价 -->
        <div class="menu-ul-3" v-if="menuActive == 3">
          <div v-if="evaluateData.length > 0" class="evaluate-list-box">
            <div
              v-for="(item, inx) in evaluateData"
              :key="inx"
              class="evaluate-option"
            >
              <div class="left-imgs-box">
                <img v-if="item.user_photo" :src="item.user_photo" />
                <img v-else src="../../assets/user.png" />
              </div>
              <div class="right-content-box">
                <div class="one-lines">
                  <div class="user-name own">{{ item.user_name }}</div>
                  <div class="user-stars">
                    <div
                      v-for="(i, index) in starList"
                      :key="index"
                      class="star-items"
                    >
                      <img :src="item.level > index ? stara : starb" />
                    </div>
                  </div>
                  <div
                    v-if="userAllInfo && userAllInfo.id == item.user_id"
                    @click="remoeEvaluate(item)"
                    class="delete-icon"
                  >
                    <img src="../../assets/icon-delete.png" />
                  </div>
                </div>
                <div class="content-text">{{ item.content }}</div>
                <div class="times-box">{{ item.created_at }}</div>
              </div>
            </div>
          </div>
          <div v-else class="evaluate-list-box" style="text-align: center">
            暂无评价内容
          </div>

          <div
            v-if="userAllInfo && userAllInfo.id && this.classDetail1.type == 1"
            class="evaluate-form-box"
          >
            <div class="t-title">
              <div class="txt">发表评论</div>
            </div>
            <div class="evaluate-lines-box">
              <div class="label-box">总<span></span>评：</div>
              <div class="star-num-box">
                <div
                  v-for="(i, index) in starList"
                  :key="index"
                  @click="starChange(index)"
                  class="star-items"
                >
                  <img :src="evaluateNum > index ? stara : starb" />
                </div>
              </div>
            </div>
            <div class="evaluate-lines-box">
              <div class="label-box">评价内容：</div>
              <div class="evaluate-textarea-box">
                <textarea
                  v-model="evaluateText"
                  @change="changeEvaluate"
                  rows="2"
                  maxLength="50"
                  placeholder="可以从课程的内容，授课方式等评价课程，或者分享您学习课程的感受，课程给您带来了哪些收获。"
                ></textarea>
                <div class="limit-box">
                  <span>至少输入5个字</span>
                  <span>{{ evaluateText.length }}/50</span>
                </div>
                <p v-if="showError" class="error-info">
                  {{ textareaErrorTxt }}
                </p>
              </div>
            </div>
            <div class="evaluate-button" @click="submitEvaluate">提交</div>
          </div>
        </div>
        <div class="statement-box">
          <p>免责声明：</p>
          <p>
            ①
            凡本站注明“来源：中教云课”的所有文字、图片和音视频稿件，版权均属本网所有，任何媒体、网站或个人未经本网协议授权不得转载、链接、转贴或以其他方式复制发表。已经本站协议授权的媒体、网站，在下载使用时必须注明“来源：中教云课”，违者本站将依法追究责任。
          </p>
          <p>
            ②
            本站注明稿件来源为其他媒体的文/图等稿件均为转载稿，本站转载出于非商业性的教育和科研之目的，并不意味着赞同其观点或证实其内容的真实性。如转载稿涉及版权等问题，请作者在两周内速来电或来函联系。
          </p>
        </div>
      </div>
      <div class="fr teacher">
        <div class="t-title">
          <div class="txt">课程讲师</div>
        </div>
        <div class="t-lists" v-for="(item, i) in this.classDetail3" :key="i">
          <div class="img fl">
            <!-- <img :src="item.photo" alt /> -->
            <div
              class="photo-img"
              :style="`background-image: url('${item.photo}')`"
            ></div>
          </div>
          <div class="txt">
            <div class="title">{{ item.name }}</div>
            <div class="word">
              <p>
                {{ item.intro }}
                <em class="t-lists-btn btn-shouqi" @click="shouqi(item, i)">
                  {{ shouqiTxt[i] }}
                  <span :class="shouqiClass[i]"></span>
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Aside />
  </div>
</template>

<script>
import "./class.less";
import { Collapse, Modal, Message } from "ant-design-vue";
const { Panel } = Collapse;
import services from "../../config/services";
import utils from "../../config/utils";
import { mapActions, mapState } from "vuex";
import Aside from "../../components/Aside";

export default {
  name: "ClassDetail",
  components: {
    ACollapse: Collapse,
    ACollapsePanel: Panel,
    Aside,
  },
  data() {
    return {
      activeKey: ["0"],
      menuActive: 1,
      services: services,
      classDetail3_copy: [],
      classDetail3: [],
      maxNum: 50,
      shouqiTxt: [],
      shouqiClass: [],
      evaluateText: "",
      evaluateNum: 5,
      starList: [1, 2, 3, 4, 5],
      stara: require("../../assets/icon-star-active.png"),
      starb: require("../../assets/icon-star.png"),
      textareaErrorTxt: "",
      showError: false,
      sz_cm_id: "", //思政来源 携带资源素材id
      downInx: "",
    };
  },
  created() {
    if (this.$route.query.cmid) {
      this.sz_cm_id = this.$route.query.cmid;
    } else {
      this.downInx = "0";
    }
  },
  computed: {
    ...mapState({
      evaluateData: (state) => state.class.evaluateData,
      classDetail1: (state) => state.class.classDetail1,
      classDetail2: (state) => state.class.classDetail2,
      classDetail3C: (state) => state.class.classDetail3,
      userAllInfo: (state) => state.appsLogin.appsUserInfo,
    }),
  },
  watch: {
    classDetail3C() {
      this.classDetail3_copy = JSON.parse(
        JSON.stringify(this.$store.state.class.classDetail3)
      );
      this.shouqiTxt =
        this.$store.state.class.classDetail3.length > 0 &&
        this.$store.state.class.classDetail3.map((data) => {
          if (data.intro.length > this.maxNum) {
            return "查看更多";
          }
        });
      this.shouqiClass =
        this.$store.state.class.classDetail3.length > 0 &&
        this.$store.state.class.classDetail3.map((data) => {
          if (data.intro.length > this.maxNum) {
            return "iconfont iconbottom";
          }
        });
      const classDetailData =
        this.$store.state.class.classDetail3.length > 0 &&
        this.$store.state.class.classDetail3.map((data) => {
          if (data.intro.length > this.maxNum)
            data.intro = data.intro.substring(0, this.maxNum);
          return data;
        });
      this.classDetail3 = classDetailData;
      return this.$store.state.class.classDetail3;
    },
  },
  mounted() {
    this.getDetailsData();
  },
  methods: {
    ...mapActions([
      "loadCourseDetails",
      "loadCourseChapter",
      "loadCourseTeacher",
      "loadEvaluateLists",
    ]),
    // 获取详情数据 - 课程、章节 、讲师、评价
    getDetailsData() {
      let that = this;
      this.loadCourseDetails({ id: this.$route.params.classId });
      this.loadCourseChapter({ id: this.$route.params.classId }).then((res) => {
        if (this.sz_cm_id) {
          if (res.code == "0000") {
            if (res.data && res.data.length > 0) {
              res.data.forEach((ele, index) => {
                if (ele.children.length > 0) {
                  ele.children.forEach((sub, sinx) => {
                    if (sub.chapter_type == 1) {
                      sub.children &&
                        sub.children.length > 0 &&
                        sub.children.forEach((last, linx) => {
                          if (last.cm_id == that.sz_cm_id) {
                            that.downInx = String(index);
                          }
                        });
                    } else {
                      if (sub.cm_id == that.sz_cm_id) {
                        that.downInx = String(index);
                      }
                    }
                  });
                }
              });
            }
          }
        }
      });
      this.loadCourseTeacher({ course_id: this.$route.params.classId });
      this.loadEvaluateLists({ course_id: this.$route.params.classId });
    },
    collapseChange(key) {
      this.downInx = key;
    },
    // 点击素材去播放页事件
    materialPlay(item) {
      this.btnStudy(item.cm_id);
    },
    muluClick(num) {
      this.menuActive = num;
    },
    btnStudy(cmid) {
      let palyId = cmid ? cmid : 0;
      if (this.userAllInfo && this.userAllInfo.id) {
        if (this.classDetail1.type == 2) {
          Message.error(
            "如需观看专业课程，请拨打联系电话或申请免费试用，获得专业课程观看权限"
          );
        } else {
          let url = `/video/${this.$route.params.classId}/${palyId}`;
          if (this.$route.query.from) {
            url = url + `?from=${this.$route.query.from}`;
          }
          utils.redirect(this, url, true);
        }
      } else {
        if (window.location.href.indexOf("createFarm") > -1) {
          if (
            this.$route.query.from &&
            this.$route.query.from.indexOf("createFarm") > -1
          ) {
            this.$router.push(
              `/login?${
                this.$route.query.from &&
                this.$route.query.from.indexOf("createFarm") > -1
                  ? "from=" +
                    window.location.pathname +
                    window.location.search +
                    "&createFarm=1"
                  : ""
              }`
            );
          } else {
            this.$router.push(`/login?from=createFarm`);
          }
        } else {
          this.$router.push({
            path: "/login",
            query: { from: this.$route.query.from || this.$route.path },
          });
        }
      }
    },
    shouqi(item, i) {
      if (this.shouqiTxt[i] === "查看更多") {
        this.classDetail3[i].intro = this.classDetail3_copy[i].intro;
        var m = JSON.parse(JSON.stringify(this.shouqiTxt));
        m[i] = "收起";
        this.shouqiTxt = m;
        var n = JSON.parse(JSON.stringify(this.shouqiClass));
        n[i] = "iconfont icontop";
        this.shouqiClass = n;
      } else {
        if (this.classDetail3[i].intro.length > this.maxNum)
          this.classDetail3[i].intro = this.classDetail3[i].intro.substring(
            0,
            this.maxNum
          );
        var m2 = JSON.parse(JSON.stringify(this.shouqiTxt));
        m2[i] = "查看更多";
        this.shouqiTxt = m2;
        var n2 = JSON.parse(JSON.stringify(this.shouqiClass));
        n2[i] = "iconfont iconbottom";
        this.shouqiClass = n2;
      }
    },
    starChange(i) {
      this.evaluateNum = i + 1;
    },
    changeEvaluate() {
      if (this.evaluateText.length > 5) {
        this.showError = false;
        this.textareaErrorTxt = "";
      }
    },
    remoeEvaluate(item) {
      let that = this;
      Modal.confirm({
        title: "确定删除该条评价吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          services
            .removeCourseComment({
              id: item.id,
              data: {
                _method: "delete",
              },
            })
            .then((res) => {
              if (res.code == "0000") {
                Message.success(res.message);
                that.loadEvaluateLists({
                  course_id: that.$route.params.classId,
                });
              } else {
                Message.error(res.message);
              }
              return res;
            });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    submitEvaluate() {
      if (!this.evaluateText || this.evaluateText.length < 5) {
        this.showError = true;
        this.textareaErrorTxt = "请至少输入5个字";
        return false;
      }
      services
        .addCourseComment({
          course_id: this.$route.params.classId,
          content: this.evaluateText,
          level: this.evaluateNum,
        })
        .then((res) => {
          if (res.code == "0000") {
            this.loadEvaluateLists({ course_id: this.$route.params.classId });
            Message.success("评价成功");
          } else {
            Message.error(res.message);
          }
          this.evaluateText = "";
          this.evaluateNum = 5;
          return res;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.menu-ul-2 {
  .intro-imgs {
    padding: 20px 0;
    line-height: 1.7;
    color: #555;
    /deep/img {
      width: auto !important;
      max-width: 100% !important;
    }
  }
}
.btn-video-detail {
  margin-top: 20px;
  position: absolute;
  bottom: 12px;
}
.class-info .txt .btn-video-detail {
  bottom: 50px;
}
.class-info .class-tags {
  background: linear-gradient(90deg, #fdaa59 0%, #ff6607 100%);
  border-radius: 15px 0px 15px 0px;
  color: #fff;
  line-height: 28px;
  height: 28px;
  width: 70px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  vertical-align: 6px;
  position: absolute;
  top: 0;
  left: 0;
}
.class-info .class-tags.major {
  background: linear-gradient(90deg, #fc7394 0%, #7b00d2 100%);
}
.btn-video-detail .iconright {
  margin-left: 13px;
  font-size: 12px;
}
.btn-video-detail .btn-xuexi {
  padding: 13px 19px 13px 18px;
  background: #3370ff;
  color: #fff;
  border-radius: 26px;
  line-height: 100%;
  cursor: pointer;
}
.btn-video-detail .btn-xuexi:hover {
  background: #134bce;
  text-decoration: none;
}
.evaluate-list-box {
  width: 100%;
  padding: 20px 20px 0 20px;
}
.evaluate-list-box .evaluate-option {
  display: flex;
  padding: 20px 0 0;
}
.evaluate-list-box .left-imgs-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.evaluate-list-box .left-imgs-box img {
  width: 100%;
  height: 100%;
}
.evaluate-list-box .right-content-box {
  flex: 1;
  border-bottom: 1px dotted #d6d6d6;
  padding-bottom: 20px;
}
.evaluate-list-box .evaluate-option:last-child .right-content-box {
  border: none;
}
.evaluate-list-box .one-lines {
  width: 100%;
  margin-bottom: 15px;
}
.evaluate-list-box .one-lines .user-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  line-height: 20px;
}
.evaluate-list-box .one-lines .user-name.own {
  color: #4c78fd;
}
.evaluate-list-box .one-lines .user-stars {
  margin-left: 20px;
  display: inline-block;
}
.evaluate-list-box .delete-icon {
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
}
.evaluate-list-box .delete-icon img {
  width: 100%;
  height: auto;
}
.evaluate-list-box .star-items {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
}
.evaluate-list-box .star-items img {
  width: 100%;
  height: 100%;
}
.evaluate-list-box .content-text {
  font-size: 14px;
  color: #666;
  line-height: 26px;
  margin-bottom: 10px;
}
.evaluate-list-box .times-box {
  color: #999;
  font-size: 12px;
}
.evaluate-form-box {
  width: 100%;
  padding-top: 20px;
}
.evaluate-form-box .t-title {
  overflow: hidden;
  border-bottom: 1px solid #d6d6d6;
}
.evaluate-form-box .t-title .txt {
  font-size: 20px;
  float: left;
  height: 36px;
  border-bottom: 3px solid #3366ff;
  padding: 0 3px;
}
.evaluate-form-box .evaluate-lines-box {
  padding: 20px 0;
  position: relative;
}
.evaluate-form-box .label-box {
  font-size: 14px;
  color: #666;
  width: 70px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  position: absolute;
}
.evaluate-form-box:first-child .label-box {
  position: static;
}
.evaluate-form-box .label-box span {
  display: inline-block;
  width: 28px;
  vertical-align: top;
}
.evaluate-form-box .star-num-box {
  vertical-align: top;
  display: block;
  margin-left: 80px;
}
.evaluate-form-box .star-num-box .star-items {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.evaluate-form-box .star-num-box .star-items img {
  width: 100%;
  height: 100%;
}
.evaluate-form-box .evaluate-textarea-box {
  display: block;
  vertical-align: top;
  padding: 10px 10px 5px;
  border: 1px solid #e0dfe4;
  margin-left: 80px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
}
.evaluate-form-box .evaluate-textarea-box .error-info {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: #c40000;
  left: 10px;
}
.evaluate-form-box .evaluate-textarea-box textarea {
  border: none;
  line-height: 24px;
  width: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
}
.evaluate-form-box .limit-box {
  color: #999;
  font-size: 12px;
  text-align: right;
}
.evaluate-form-box .limit-box span {
  display: inline-block;
  margin-left: 10px;
}
.evaluate-form-box .evaluate-button {
  background: #3370ff;
  color: #fff;
  border-radius: 26px;
  line-height: 40px;
  height: 40px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  float: right;
}
textarea::-webkit-input-placeholder {
  color: #999;
}
textarea::-moz-placeholder {
  color: #999;
}
textarea:-moz-placeholder {
  color: #999;
}
textarea:-ms-input-placeholder {
  color: #999;
}
.statement-box {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 24px;
  background: #f5f6f9;
  border-radius: 4px;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 60px;
}
.all-bg {
  background-color: #fff;
}
</style>
